<template>
    <div class="about">
        <v-card class="ma-3 pa-3">
            <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0" sm="12" md="12">
                    <v-parallax
                        dark
                        style="height: 130px"
                        src="@/assets/background-azul-escuro-reduzido-2.jpg"
                    >
                        <v-row align="center" justify="center">
                            <v-col class="text-center" cols="12">
                                <h1 class="display-1 font-weight-thin mb-4">
                                    Fluxo de Protesto
                                </h1>
                                <h3 class="heading">Recuperi | Agosto | 2024</h3>
                            </v-col>
                        </v-row>
                    </v-parallax>
                </v-col>
            </v-row>
            <div class="mt-3" style="height: 650px">
                <object
                    :data="linkToPdf"
                    type="application/pdf"
                    class="pdf-container"
                >
                    <iframe :src="`${linkToPdf}`"></iframe>
                </object>
                <!-- <embed :src="`${linkToPdf}`" class="pdf-container" /> -->
            </div>
        </v-card>
    </div>
</template>

<style>
.pdf-container {
    width: 100%;
    height: 100%;
}
</style>

<script>
export default {
    data() {
        return {
            linkToPdf: '/fluxo_de_protesto_recuperi_v2.pdf'
        };
    }
};
</script>
